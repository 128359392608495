import './WhoAreWe.scss';
// import logo from "../imgs/logo.png"

import whoAreWe1Img from '../imgs/whoAreWe1.png';
import whoAreWe2Img from '../imgs/whoAreWe2.png';
import whoAreWe3Img from '../imgs/whoAreWe3.png';
import whoAreWe4Img from '../imgs/whoAreWe4.png';

export const WhoAreWe = () => {
    return (
        <div className="whoAreWeContainer" id="why">
            <h2>Who Are We?</h2>
            <br></br>
            <h3>
            Avant is committed to empowering students to pursue their life-changing academic journey. We are passionate about each student's journey and will offer the most comprehensive and customized education consulting services to each student. Join us in exploring the best opportunities and having complete control over the applications to academic institutions. 
            </h3>
            <div className="infoRow">
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="percent" src={whoAreWe1Img} alt="75%" />
                    </div>
                    <h4>Individualized Experience</h4>
                    <p>We will create a customized experience that tailors to every student's needs, with careful considerations of every student's educational goals. </p>
                </div>
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="sad" src={whoAreWe2Img} alt="sad" />
                    </div>
                    <h4>Fast, Easy, Transparent</h4>
                    <p>Our application process is transparent as we will inform you on the outcome at every step of the way</p>
                </div>
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="decrease" src={whoAreWe3Img} alt="decrease" />
                    </div>
                    <h4>Professional Guidance</h4>
                    <p>Our team of experts is here to provide you with professional strategies and recommendations to build your education and careers.</p>
                </div>
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="decrease" src={whoAreWe4Img} alt="decrease" />
                    </div>
                    <h4>End-to-end Services</h4>
                    <p>We are here to guide you through every step of the application that leads to a successful path. </p>
                </div>
            </div>
        </div>
    );
}
