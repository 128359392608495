import React from 'react';
import './Hero.scss';
import logo from "../imgs/logo.png"

export const Hero = () => {
    return (
      <div className="heroContainer" id="hero">
            <div className="centered">
                <img className="logo" src={logo} alt='logo'/>
                <div className="subtitle">
                    <h1>All Your Education Needs</h1>
                    <h2>In just one place</h2>
                </div>
            </div>
            <div className="arrowDown">
                <i className="arrow"></i>
            </div>
      </div>
    );
}
