import './AboutUs.scss';
// import logo from "../imgs/logo.png"

import aboutUs1Img from '../imgs/aboutUs1.png';
import aboutUs2Img from '../imgs/aboutUs2.png';
import aboutUs3Img from '../imgs/aboutUs3.png';

export const AboutUs = () => {
    return (
        <div className="aboutUsContainer" id="why">
            <h2>About Us</h2>
            <br></br>
            <div className="infoRow">
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="percent" src={aboutUs1Img} alt="75%" />
                    </div>
                    <h4>Why Avant Consulting?</h4>
                    <p>Avant Consulting helps create an unmatchable study experience for students. We are a global leader in education services, and can connect students with the right institutions and provide you with the most suitable recommendations. We will help you pursue your study and make your dream come true.</p>
                </div>
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="sad" src={aboutUs2Img} alt="sad" />
                    </div>
                    <h4>Our Team</h4>
                    <p>The Avant Consulting team consists of a group of experts that works to empower students pursue their academic success. We strive to help students achieve their dreams with determination, commitment, and excellence. While tailoring to every student's individual needs, we will create opportunities and guidance to lead students to the path of success. </p>
                </div>
                <div className="vertical">
                    <div className="imgWrapper">
                        <img className="decrease" src={aboutUs3Img} alt="decrease" />
                    </div>
                    <h4>Value Proposition and Mission</h4>
                    <p>We understand how important the application process is as part of the student's academic journey, and we are here to help. We offer a well-rounded consultation service that tailors to every student's needs, and will support the process from end-to-end to help students achieve above and beyond.</p>
                </div>
            </div>
        </div>
    );
}
