import React from 'react';
import interfaceImg from '../imgs/interface.png';
import './Services.scss';

import interface1Img from '../imgs/interface1.svg';
import interface2Img from '../imgs/interface2.svg';

export const Interface = () => {
    return (
        <div className="servicesContainer" id="interface">
            <div className="interfaceMain">
                <img src={interfaceImg} alt="interface" />
                <div className="interfaceText">
                    <h2>Our Services</h2>
                    <p>Based on every student's needs, we will customize an experience that is the most suitable to ensure the success of each student. Our team of experts will provide recommendations on which services would be the most suitable. Contact us (TODO hyperlink to #5 Contact Us) for more information. </p>
                </div>
            </div>
            <div className="interfaceSub">
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="lessImg" src={interface1Img} alt ="less" />
                    </div>
                    <h4>School Applications</h4>
                    <p>We will provide students with recommendations on the most suitable schools, and send in applications on behalf of students to ensure success.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Professional Consultation</h4>
                    <p>Our team of experts will provide professional consultation on how to customize the student's overall academic journey to achieve the best results.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Interview and Resume Coaching</h4>
                    <p>We specialize in coaching students to deliver with excellence and integrity in many areas, including resume screening, interview preparation, and more.</p>
                </div>
            </div>
            <div className="interfaceSub">
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="lessImg" src={interface1Img} alt ="less" />
                    </div>
                    <h4>Extracurricular Activities Assistance</h4>
                    <p>We understand the way extracurricular activities complement an academic curriculum and we will provide assistance on finding an activity that is suitable for the students.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Volunteer Opportunities Assistance</h4>
                    <p>We can provide guidance on volunteer opportunities as we recognize that community engagement is an important aspect of overall career planning.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Continued Homestay Support</h4>
                    <p>We will provide the most efficient homestay support and ensure that the network is safe, personalized, supported and practical. </p>
                </div>
            </div>
            <div className="interfaceSub">
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="lessImg" src={interface1Img} alt ="less" />
                    </div>
                    <h4>Tutoring Services</h4>
                    <p>We partner with prestigious academic institutions that can provide students with unmatched tutoring services to help students advance in their academic journey.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Translation of Documentation</h4>
                    <p>Our team will provide translation services for those documents that need to be sent in as part of the application. Additional services may be required if notarization is needed.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Arrival Transportation Support</h4>
                    <p>We will arrange professional transportation to ensure students are supported from the moment they arrive.</p>
                </div>
            </div>
            <div className="interfaceSub">
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="lessImg" src={interface1Img} alt ="less" />
                    </div>
                    <h4>Long-term Transportation and Flight-Booking Assistance</h4>
                    <p> We've got you covered anytime, anywhere with great deals.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Recommendation for Financial Assistance Application and Mobile Phone Plans</h4>
                    <p>We will find and compare the best deals for the students to ensure they feel supported.</p>
                </div>
                <div className="subContainer">
                    <div className="imgWrapper">
                        <img className="customImg" src={interface2Img} alt ="custom" />
                    </div>
                    <h4>Regular Updates &amp; Continued Support</h4>
                    <p>We will keep students and parents informed at all times, and will answer any questions or concerns you may have during this time.</p>
                </div>
            </div>
        </div>
    );
}
