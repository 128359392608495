import { useState, useRef, useEffect } from 'react';

import { Hero } from "./components/Hero";
import { WhoAreWe } from './components/WhoAreWe';
import { SubClause } from './components/SubClause';
import { Interface } from './components/Services';
import { AppProcess } from './components/AppProcess';
import Customer from './components/Customer';
import Console from './components/Console';
import Contact from './components/Contact';

import "./global.scss"
import { Talk } from './components/Talk';
import { AboutUs } from './components/AboutUs';
// import PageCounter from './components/PageCounter';

export const App = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      //@ts-ignore
        setVantaEffect(window.VANTA.NET({
            el: myRef.current,
            minHeight: window.innerHeight,
            minWidth: window.innerWidth,
            color: 0x3aedfb,
            backgroundColor: 0x0c53a0,
            showDots: true,
            points: 27,
            maxDistance: 15,
        }));
    }
    return () => {
        //@ts-ignore
        if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="App">
      <div ref={myRef} className="background">
        <div className="overlay"></div>
      </div>
      <Hero/>
      <WhoAreWe />
      <SubClause />
      <Interface />
      <AppProcess />
      <AboutUs/>
      {/* <Customer /> */}
      {/* <Console /> */}
      {/* <Talk /> */}
      <Contact />
      {/* <PageCounter /> */}
    </div>
  );
}
