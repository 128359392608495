import nodemailer from 'nodemailer';
import Mail from 'nodemailer/lib/mailer';
import React, { useState } from 'react';
import axios from 'axios';
import './Contact.scss';
import weChatImg from '../imgs/wechat.jpg';
import whatsAppImg from '../imgs/whatsApp.jpg';

import checkImg from '../imgs/greenCheck.svg';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    const [nameValid, setNameValid] = useState(false)
    const [emailValid, setEmailValid] = useState(false)
    const [msgValid, setMsgValid] = useState(false)

    const [mailSuccess, setMailSuccess] = useState(false);

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const updateName = (e : any) => {
        if (e.target.value.length > 0) setNameValid(true);
        else setNameValid(false);
        setName(e.target.value);
    }
    const updateEmail = (e : any) => {
        if (validateEmail(e.target.value)) setEmailValid(true);
        else setEmailValid(false);
        setEmail(e.target.value);
    }
    const updateMsg = (e : any) => {
        if (e.target.value.length > 0) setMsgValid(true);
        else setMsgValid(false);
        setMsg(e.target.value);
    }

    const isValid = () => {
        // return true;
        if (!nameValid) return false;
        if (!emailValid) return false;
        if (!msgValid) return false;
        return true;
    }

    const onSubmit = async (e : any) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('name', `${name}`);
        formData.set('email', `${email}`);
        formData.set('msg', `${msg}`);

        if (!isValid()) return;

        console.log('hjoiwerwer');

        const transporter = nodemailer.createTransport({
            service: 'gmail',
            auth: {
                user: 'no-reply@socialdiversity.org', // generated ethereal user
                pass: 'sdcreplynope:)', // generated ethereal password
            },
        });

        const mailOptions : Mail.Options = {
            from: 'SDC Foundation <no-reply@socialdiversity.org>',
            to: [email],
            subject: 'Your tickets have arrived!',
            html: `<p>
                Thank you for your participation in the 2022 Brivia Once Upon a Shine Gala,
                Presented by BakerWest and Vancouver Career College.
                <br/>
                Please scan the attached QR codes to redeem your tickets. We look forward to seeing
                you at Westin Bayshore on September 3rd, 2022!
            </p>`,
        };

        await transporter.sendMail(mailOptions);

        console.log('hjoiwerwer');

        setMailSuccess(true);

    }
    return (
      <div className="contactContainer" id="contact">
            <div className="modal">
                {/* {!mailSuccess
                ? <>
                    <div className="header">
                        <h3>Don't hesitate.</h3>
                        <h2>Contact Us</h2>
                    </div>
                    <div className="contactMain">
                        <form onSubmit={onSubmit}>
                            <div className="inputBox">
                                <span>Name</span>
                                <input id="name" required className={!nameValid ? 'error': ''} onChange={updateName}/>
                            </div>
                            <div className="inputBox">
                                <span>Email</span>
                                <input id="email" required type="email" className={!emailValid ? 'error': ''} onChange={updateEmail}/>
                            </div>
                            <div className="inputBox">
                                <span>Message</span>
                                <textarea id="msg" required className={!msgValid ? 'error': ''} onChange={updateMsg}/>
                            </div>
                            <button disabled={!isValid()}>Submit</button>
                        </form>
                    </div>
                </>
                : (<div className="mailSuccess">
                    <img src={checkImg} alt='succes'/>
                    <h3>Thank you!</h3>
                    <h2>We will be in touch within 24 hours.</h2>
                </div>)
                } */}
                    <div className="header">
                        <h3>Don't hesitate.</h3>
                        <h2>Contact Us</h2>
                    </div>
                    <div className='imgRow'>
                        <div className='vertical'>
                            <h4>Wechat</h4>
                            <div className="imgWrapper">
                                <img src={weChatImg} alt="Wechat" />
                            </div>
                        </div>
                        <div className='vertical'>
                            <h4>WhatsApp</h4>
                            <div className="imgWrapper">
                                <img src={whatsAppImg} alt="WhatsApp" />
                            </div>
                        </div>
                    </div>
                <h4 className="copyright">Copyright 2022. Eatego Technologies Inc. All rights reserved.</h4>
            </div>
      </div>
    );
}

export default Contact;
