import './SubClause.scss';

export const SubClause = () => {
    return (
      <div className="oneDayContainer">
            <h3>How does getting into your dream school sound?</h3>
            <p>Pretty good right?</p>
            <p>Avant can help you achieve that. And more.</p>
      </div>
    );
}
