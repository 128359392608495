import './AppProcess.scss';

export const AppProcess = () => {
    return (
      <div className="appProcessContainer">
        <h3>Application Process</h3>
          <ol>
            <li>Consultation
              <ul>
                <li>In-depth consultation with experts will be conducted to further understand students' needs and background.</li>
              </ul>
            </li>
            <li>Proposal and Contract
              <ul>
                <li>A proposal and contract will be prepared and signed by both parties with a mutual understanding of the plan going forward.</li>
              </ul>
            </li>
            <li>Detailed Academic Plan
              <ul>
                <li>A detailed plan will be prepared outlining the student's opportunities that best prepares the students' academic journey.</li>
              </ul>
            </li>
            <li>Selection of Academic Schools and Other Services
              <ul>
                <li>Students and parents will have the opportunity to make final selections from the plan.</li>
              </ul>
            </li>
            <li>Gathering of Documentation and Additional Information
              <ul>
                <li>Our team of experts will gather all information and documentation required to complete the application process.</li>
              </ul>
            </li>
            <li>Application submission
              <ul>
                <li>We will make the final submission to all the selected institutions and await admission.</li>
              </ul>
            </li>
            <li>Support for Post-Admission
              <ul>
                <li>All results will be communicated with the student and the parents, and further assistance will be provided to complete the process.</li>
              </ul>
            </li>
            <li>Support for Pre-Departure
              <ul>
                <li>Assistance will be provided to support the student’s departure and arrival at the final destination. </li>
              </ul>
            </li>
          </ol>
      </div>
    );
}
